import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-notifications-component/dist/theme.css'
import { RouterProvider, createBrowserRouter, Outlet, ScrollRestoration } from 'react-router-dom';
import LoadingScreen from './pages/LoadingScreen';
import Home from './pages/Home';
import JoinMatch from './pages/JoinMatch';
import MyMatches from './pages/MyMatches';
import { useDispatch } from 'react-redux';
import {
  addLiveQuestion,
  setCloseOrOpenBet,
  setConnectionStatus,
  setLiveScores,
  setSocket,
  setTotalBetJoin
} from './pages/redux/features/webSocketSlice';
import { useEffect, useState } from 'react';
import { ReactNotifications, Store } from 'react-notifications-component'


const AppLayout = () => (
  <>
    <ScrollRestoration />
    <Outlet />
  </>
);

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <LoadingScreen />,
      },
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/join-match',
        element: <JoinMatch />,
      },
      {
        path: '/my-matches',
        element: <MyMatches />,
      },
    ],
  },
]);

const App = () => {
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [resultWinOrLoss, setResultWinOrLoss] = useState(false);
  const [result, setResult] = useState({});

  const userId = "8376944575";

  useEffect(() => {
    // const socket = new WebSocket("wss://backend.gamehub60.com/ws/");
    const socket = new WebSocket(process.env.REACT_APP_WS, "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4Mzc2OTQ0NTc1IiwidXNlck5hbWUiOiI4Mzc2OTQ0NTc1IiwidXNlckVtYWlsIjoiODM3Njk0NDU3NUBnbWFpbC5jb20iLCJpYXQiOjE3MzE0MTEzMTl9.0ESWDfFoK_PTRXCzK1Pp0sfrJ4I2Q8AHvLna9sMbofo");

    socket.onopen = () => {
      console.log("WebSocket connection established");
      dispatch(setSocket(socket.url));
      dispatch(setConnectionStatus(true));

      const subscribeMessage = {
        gameType: "cricket",
        type: "SUBSCRIBE",
      };
      socket.send(JSON.stringify(subscribeMessage));
      console.log("WebSocket subscribe");
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("Received live data:", message);

      if (message.type === "LIVE_SCORES") {
        dispatch(setLiveScores(message.liveScores));
      }
      if (message.type === "NEW_QUESTION") {
        dispatch(addLiveQuestion(message?.questionData));
      }
      if (message.type === "BET_STATUS") {
        dispatch(setCloseOrOpenBet(message));
        message?.isOpenForBet ? setNotificationMessage("Match Started") :
          setNotificationMessage("Match Over");
        setShowToast(true);
        // setInterval(() => {
        //   setShowToast(false)
        // }, 5000);
      }
      if (message.type === "TOTAL_BETS") {
        dispatch(setTotalBetJoin(message));
      }
      if (message.type === "BET_RESULT") {
        setResultWinOrLoss(true);
        setResult(message?.Bet);
        setInterval(() => {
          setResultWinOrLoss(false)
        }, 5000);
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (showToast) {
      Store.addNotification({
        title: "Match!",
        message: notificationMessage,
        type: `${notificationMessage === "Match Started" ? "success" : "danger"}`,
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }, [])

  return (
    <>

      <ReactNotifications className='position-fixed top-0 start-0 end-0' />
      {userId === result?.userId && resultWinOrLoss &&
        <>
          <div
            className="modal-backdrop fade show"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1040,
            }}
          ></div>
          <div
            className="modal show"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              zIndex: 1050,
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <div className="w-100-modelBox w-22 modal-dialog modal-dialog-centered mx-3">
              <div className="modal-content">
                <div className="modal-header py-2 d-flex justify-content-between modelHeader text-white">
                  <h3 className="modal-title flex-fill text-center fw-bolder">
                    You {result.result}!
                  </h3>
                  <i
                    onClick={() => setResultWinOrLoss(false)}
                    className="bi bi-x-circle fs-2"
                    style={{ cursor: "pointer" }}
                    aria-label="Close"
                  ></i>
                </div>
                <div className="modal-body p-0 pt-3 text-center">
                  <div className="p-0 pb-2 d-flex align-items-center">
                    <div className="col">
                      <img src={result.teamA?.image} className="custom-img rounded-circle" alt={result.teamA?.name} />
                      <h6 className="fw-bold pt-1">{result.teamA?.name}</h6>
                    </div>
                    <div className="col text-center fs-12">
                      <p className="m-0 text-danger">{result.league}</p>
                      <i className="m-0 fw-bold text-secondary">V/S</i>
                      <p className="m-0">{result.matchTime} {result.matchDate}</p>
                    </div>
                    <div className="col">
                      <img src={result.teamB?.image} className="custom-img rounded-circle" alt={result.teamB?.name} />
                      <h6 className="fw-bold pt-1">{result.teamB?.name}</h6>
                    </div>
                  </div>
                  <div className="mx-2">
                    <h4
                      className={`mb-1 mt-3 ${result.result === "win" ? "text-success" : "text-danger"
                        }`}
                    >
                      {result.result === "win" ? "Winning" : "Lost"} Amount
                    </h4>
                    <h1
                      className={`mx-5 fw-bolder text-center py-2 rounded text-white ${result.result === "win" ? "greenShadowBg" : "bg-danger"
                        }`}
                    >
                      &#x20B9;{result.winningAmount}
                    </h1>
                  </div>
                  <div className="m-3 text-start">
                    <h6 className="fw-bold mb-1">Bet on</h6>
                    <div className="rounded lightGrayBg fs-12 py-1 px-2 mb-3">
                      {result.question}
                    </div>
                    <div className="d-flex gap-5">
                      <div className="w-50">
                        <h6 className="fw-bold mb-1">Your Bet</h6>
                        <div className="rounded lightGrayBg fs-12 py-1 px-2">
                          {result.choice === "teamA"
                            ? result.teamA?.name
                            : result.choice === "teamB"
                              ? result.teamB?.name
                              : result.choice}
                        </div>
                      </div>
                      <div className="w-50">
                        <h6 className="fw-bold mb-1">Bet Amount</h6>
                        <div className="rounded lightGrayBg fs-12 py-1 px-2">
                          &#x20B9;{result.betAmount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <span className="fw-bold">Result: </span>
                  <span>{
                    result.result === "win"
                      ? (result.winningChoice === "teamA"
                        ? result.teamA?.name
                        : result.winningChoice === "teamB"
                          ? result.teamB.name
                          : result.winningChoice)
                      : result.result === "loss"
                        ? (result.winningChoice === "teamA"
                          ? result.teamB.name
                          : result.winningChoice === "teamB"
                            ? result.teamA.name
                            : result.winningChoice === "yes"
                              ? "No"
                              : result.winningChoice === "no"
                                ? "Yes"
                                : null)
                        : null
                  }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>}
      <RouterProvider router={router} />
    </>);
};

export default App;
// import React, { useState, useEffect } from 'react';
// import './App.css';

// function App() {
//   const [ballState, setBallState] = useState({
//     runs: 0,
//     four: false,
//     six: false,
//     bye: 0,
//     legBye: 0,
//     noBall: 0,
//     noBallRuns: 0,
//     isWicket: false,
//   });

//   const [myArray, setMyArray] = useState(Array(6 + ballState.noBall).fill(null));
//   console.log(myArray);

//   function getRunByBall() {
//     if (ballState.isWicket) return 'W';
//     if (ballState.four) return 4;
//     if (ballState.six) return 6;
//     if (ballState.bye > 0) return `${ballState.bye}b`;
//     if (ballState.legBye > 0) return `${ballState.legBye}lb`;
//     if (ballState.noBall > 0) return `${ballState.noBall}nb`;
//     if (ballState.noBallRuns > 0) return ballState.noBallRuns;
//     return ballState.runs;
//   }

//   function updateArray(ballResult) {
//     setMyArray((prevArray) => {
//       const newArray = [...prevArray];
//       const index = newArray.findIndex(result => result === null);
//       if (index !== -1) {
//         newArray[index] = ballResult;
//       }
//       return newArray;
//     });
//   }

//   function handleBallEvent(eventData) {
//     setBallState(prevState => ({
//       ...prevState,
//       ...eventData,
//     }));
//   }

//   useEffect(() => {
//     if (ballState.runs !== 0 || ballState.four || ballState.six || ballState.isWicket || ballState.noBall > 0) {
//       const ballResult = getRunByBall();
//       updateArray(ballResult);
//     }
//   }, [ballState]);
//   console.log(ballState);

//   const simulateBallEvent6 = () => {
//     handleBallEvent({
//       runs: 0,
//       four: false,
//       six: true,
//       bye: 0,
//       legBye: 0,
//       noBall: 0,
//       noBallRuns: 0,
//       isWicket: false,
//     });
//   };

//   const simulateBallEvent2 = () => {
//     handleBallEvent({
//       runs: 2,
//       four: false,
//       six: false,
//       bye: 0,
//       legBye: 0,
//       noBall: 0,
//       noBallRuns: 0,
//       isWicket: false,
//     });
//   };

//   const simulateNoBallEvent = () => {
//     handleBallEvent({
//       runs: 0,
//       four: false,
//       six: false,
//       bye: 0,
//       legBye: 0,
//       noBall: 1,
//       noBallRuns: 1,
//       isWicket: false,
//     });
//   };

//   return (
//     <div className="App">
//       <header className="App-header">
//         <h1>Ball-by-Ball Tracker</h1>

//         <div className="ball-result">
//           <h2>Current Ball Result:</h2>
//           <div>{getRunByBall()}</div>
//         </div>

//         <button onClick={simulateBallEvent6}>Simulate Ball Event (6 runs)</button>
//         <button onClick={simulateBallEvent2}>Simulate Ball Event (2 runs)</button>
//         <button onClick={simulateNoBallEvent}>Simulate No-Ball Event</button>

//         <div className="event-log">
//           <h3>Ball Results Array:</h3>
//           <div>
//             {myArray.map((result, index) => (
//               <div key={index}>
//                 Ball {index + 1}: {result || 'No result yet'}
//               </div>
//             ))}
//           </div>
//         </div>
//       </header>
//     </div>
//   );
// }

// export default App;
