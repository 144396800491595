import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    socket: null,
    isConnected: false,
    liveScores: [],
    liveQuestions: [{
        question: {
            questionId: 1,
            question: "Who will win the toss?",
            isCheckedByAdmin: false,
        },
    },
    {
        question: {
            questionId: 2,
            question: "Who will bat first?",
            isCheckedByAdmin: false,
        },
    },
    {
        question: {
            questionId: 3,
            question: "Who will win the match?",
            isCheckedByAdmin: false,
        },
    },
    ],
    closeOrOpenBet: {},
    totalBetJoin: [],
};

const webSocketSlice = createSlice({
    name: "webSocket",
    initialState,
    reducers: {
        setSocket: (state, action) => {
            state.socket = action.payload;
        },
        setConnectionStatus: (state, action) => {
            state.isConnected = action.payload;
        },
        setLiveScores: (state, action) => {
            state.liveScores = action.payload;
        },
        addLiveQuestion: (state, action) => {
            const question = action.payload;
            const existingQuestionIndex = state.liveQuestions.findIndex(
                (q) => q.question.questionId === question.question.questionId
            );
            if (existingQuestionIndex !== -1) {
                state.liveQuestions[existingQuestionIndex] = question;
            } else {
                state.liveQuestions.push(question);
            }
        },
        setCloseOrOpenBet: (state, action) => {
            state.closeOrOpenBet = action.payload;
        },
        setTotalBetJoin: (state, action) => {
            const betJoin = action.payload;
            const existingBetJoinIndex = state.totalBetJoin.findIndex(
                (q) => q?.questionId === betJoin.questionId
            );
            if (existingBetJoinIndex !== -1) {
                state.totalBetJoin[existingBetJoinIndex] = betJoin;
            } else {
                state.totalBetJoin.push(betJoin);
            }
        }
    },
});

export const {
    setSocket,
    setConnectionStatus,
    setLiveScores,
    addLiveQuestion,
    setCloseOrOpenBet,
    // setOpenBet,
    setTotalBetJoin
} = webSocketSlice.actions;

export default webSocketSlice.reducer;
