import React from "react";
import { useNavigate } from "react-router-dom";

const LoadingScreen = () => {
  const navigate = useNavigate();

  return (
    <div
      className="d-flex justify-content-center align-items-center position-relative w-100"
      style={{ height: "100vh" }}
      onClick={() => navigate("/home")}
      type="button"
    >
      <img
        src={`https://s3-alpha-sig.figma.com/img/4682/e0bb/d8e64b10115f160c4612551b0efcdf86?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qg4boOy4BEtXJQHX-GLu84PEBRo99kZrjyKgYRIQFEIukkQkXrsWZP9gRtbqlwZsUySafJOw9kWwo6meqXpVCFGWqsiLWn7JI5-Zh9nzY20g0w4JOUCK-1EKW0lXiK6mBCsUbNCEHBF6p68ECW4L0je8gnhrHkdKgePgN3gDV7nOsOYKd8ikkCkQ4ukXFwHfoFAVR0Tv3CklWO3YJKVrpKGMmjBrYT7xKhX2OtLgnTFyKkRanL9BGwZOyqETnxxcCe5Asb89d69IVXFz4JOM~DKVAPKrgx-uX-DduJnwHBHlufgSiChfVLqUqJ5wAYQBwTB0E6Ii8uRyxJu5xsqwbw__`}
        alt="Loading"
        className=" w-100 h-100"
      />
    </div>
  );
};

export default LoadingScreen;
