import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyMatch from "./components/MyMatch";
import { useDispatch, useSelector } from "react-redux";
import { getUserBets } from "./redux/features/cricketSlice";
import { Placeholder } from "react-bootstrap";
import RedBall from "../img/RedBall.png"
import Sort from "../img/Sort.png"
import Sort2 from "../img/sort2.png"

const MyMatches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sortByDateModel, setSortByDateModel] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState("");
  const userId = "8376944575";

  const { isLoading, betMatches, winBets,
    lossBets,
    pendingBets,
    dateBets, isError } = useSelector(
      (state) => state.cricket
    );

  useEffect(() => {
    if (betMatches.length === 0) {
      dispatch(getUserBets({ userId }));
    }
  }, [dispatch, userId]);

  const getUserByStatus = (status) => {
    if (status === 'win' && winBets <= 0) {
      dispatch(getUserBets({ userId, status }));
    }
    if (status === 'loss' && lossBets <= 0) {
      dispatch(getUserBets({ userId, status }));
    }
    if (status === 'pending' && pendingBets <= 0) {
      dispatch(getUserBets({ userId, status }));
    }
  };
  const getUserByDate = () => {
    setSortByDateModel(false);
    dispatch(getUserBets({ userId, status: 0, startDate, endDate }));
  };

  return (
    <>
      <main className="shadow">
        <header className="header position-sticky">
          <div className="d-flex align-items-center px-4 ">
            <i
              onClick={() => navigate("/home")}
              className="bi bi-arrow-left-circle me-2 fs-3"
              type="button"
            ></i>
            <h5 className="m-0 fw-bolder flex-grow-1 text-center py-3">
              My Matches
            </h5>
          </div>
          <div className="text-black bodyBgColor">
            <div className="d-flex px-2 py-3 gap-1">
              <button
                className={`flex-fill text-center border d-flex align-items-center rounded-pill lightGrayBg fs-12 fw-bold py-2 ${status === "win" ? "bg-secondary fs-12 text-white" : ""
                  }`}
                onClick={() => {
                  setStatus("win");
                  getUserByStatus("win");
                }}
              >
                <img src={RedBall} alt="ball" />
                <div className="flex-fill">Win</div>
              </button>
              <button
                className={`flex-fill border d-flex align-items-center rounded-pill lightGrayBg fs-12 fw-bold py-1 ${status === "loss" ? "bg-secondary fs-12 text-white" : ""
                  }`}
                onClick={() => {
                  setStatus("loss");
                  getUserByStatus("loss");
                }}
              >
                <img src={RedBall} alt="ball" />
                <div className="flex-fill">Lost</div>
              </button>
              <button
                className={`flex-fill border d-flex align-items-center rounded-pill lightGrayBg fs-12 fw-bold py-1 ${status === "pending" ? "bg-secondary fs-12 text-white" : ""
                  }`}
                onClick={() => {
                  setStatus("pending");
                  getUserByStatus("pending");
                }}
              >
                <img src={RedBall} alt="ball" />
                <div className="flex-fill">Pending</div>
              </button>
              <button
                className={`flex-fill border d-flex align-items-center rounded-pill lightGrayBg fs-12 fw-bold py-1 ${status === "date" && startDate && endDate
                  ? "bg-secondary text-white"
                  : ""
                  }`}
                onClick={() => {
                  setStatus("date");
                  setSortByDateModel(true);
                }}
              >
                {/* <i className="bi bi-sort-down-alt pe-1 text-danger fs-5"></i> */}
                <img src={Sort} alt="." />
                <div className="flex-fill">Sort By Date</div>
                <img src={Sort2} alt="." />
              </button>
              {sortByDateModel && (
                <>
                  <div
                    className="modal-backdrop fade show"
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 1040,
                    }}
                  ></div>
                  <div
                    className="modal show"
                    style={{
                      display: "flex",
                      position: "fixed",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 1050,
                      left: 0,
                      top: 0,
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <div className="w-100-modelBox w-22 modal-dialog modal-dialog-centered mx-3 w-380">
                      <div className="modal-content">
                        <div className="modal-header py-2 d-flex justify-content-between modelHeader text-white">
                          <h5 className="modal-title flex-fill text-center fw-bolder">
                            Sort By Date
                          </h5>
                          <i
                            onClick={() => setSortByDateModel(false)}
                            className="bi bi-x-circle fs-2"
                          ></i>
                        </div>
                        <div className="modal-body p-0 py-3 text-center">
                          <h4 className="text-danger fw-bold ">Start Date</h4>
                          <p className="fs-12 text-secondary">
                            Select your Start date where you want to see match
                            history
                          </p>
                          <input
                            type="date"
                            className="border py-2 px-3 rounded w-75"
                            placeholder="Select Start Date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            required
                          />

                          {/* <div className="d-flex gap-1 justify-content-center">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="border py-2 px-3 rounded-start"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select Day
                            </button>
                            <div className="dropdown-menu">
                              <button className="dropdown-item">Sunday</button>
                              <button className="dropdown-item">Monday</button>
                              <button className="dropdown-item">Tuesday</button>

                              <button className="dropdown-item">
                                Wednesday
                              </button>
                            </div>
                          </div>
                          <div className="btn-group">
                            <button
                              type="button"
                              className="border py-2 px-3"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select Month
                            </button>
                            <div className="dropdown-menu">
                              <button className="dropdown-item">Jan</button>
                              <button className="dropdown-item">Feb</button>
                              <button className="dropdown-item">Mar</button>

                              <button className="dropdown-item">Apr</button>
                            </div>
                          </div>
                          <div className="btn-group">
                          <button
                            type="button"
                            className="date border py-2 px-3 rounded-end"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Select Year
                          </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">2021</button>
                            <button className="dropdown-item">2022</button>
                            <button className="dropdown-item">2023</button>

                            <button className="dropdown-item">2024</button>
                          </div>
                          </div>
                        </div> */}
                        </div>
                        <div className="modal-body p-0 py-3 text-center">
                          <h4 className="text-danger fw-bold ">End Date</h4>
                          <p className="fs-12 text-secondary">
                            Select your End date where you want to see match
                            history
                          </p>
                          <input
                            type="date"
                            className="border py-2 px-3 rounded w-75"
                            placeholder="Select End Date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            required
                          />

                          {/* <div className="d-flex gap-1 justify-content-center">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="border py-2 px-3 rounded-start"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select Day
                            </button>
                            <div className="dropdown-menu">
                              <button className="dropdown-item">Sunday</button>
                              <button className="dropdown-item">Monday</button>
                              <button className="dropdown-item">Tuesday</button>

                              <button className="dropdown-item">
                                Wednesday
                              </button>
                            </div>
                          </div>
                          <div className="btn-group">
                            <button
                              type="button"
                              className="border py-2 px-3"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select Month
                            </button>
                            <div className="dropdown-menu">
                              <button className="dropdown-item">Jan</button>
                              <button className="dropdown-item">Feb</button>
                              <button className="dropdown-item">Mar</button>

                              <button className="dropdown-item">Apr</button>
                            </div>
                          </div>
                          <div className="dropdown">
                            <button
                              type="button"
                              className="border py-2 px-3 rounded-end"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select Year
                            </button>
                            <div className="dropdown-menu">
                              <button className="dropdown-item" type="button">
                                2021
                              </button>
                              <button className="dropdown-item">2022</button>
                              <button className="dropdown-item">2023</button>

                              <button className="dropdown-item">2024</button>
                            </div>
                          </div>
                        </div> */}
                        </div>
                        <div className="modal-footer justify-content-center my-2">
                          <button
                            className="text-white fs-3 fw-bold redShadowBg border rounded-pill py-1 px-5"
                            onClick={() => getUserByDate()}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </header>
        <div className="mx-smm-2 mx-smm-3">
          {isLoading
            ?
            Array.from({ length: 3 }).map((_, index) => (
              <Placeholder as="p" animation="glow" key={index}>
                <Placeholder xs={12} className="h-20 rounded" />
              </Placeholder>
            ))
            : (status === 'win' ? winBets : status === 'loss' ? lossBets : status === "pending" ? pendingBets : status === 'date' ? dateBets : betMatches).length <= 0 ?
              <div className="d-flex justify-content-center align-items-center h-80"><h5 className="text-danger text-center fw-bold">No Bets Found</h5></div>
              :
              (status === 'win' ? winBets : status === 'loss' ? lossBets : status === "pending" ? pendingBets : status === 'date' ? dateBets : betMatches)
                ?.data?.map((match) => (
                  <MyMatch match={match} key={match.betId} />
                ))
          }
        </div>
      </main>
    </>
  );
};

export default MyMatches;
