import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCricketDetails = createAsyncThunk(
    'cricket/getCricketDetails',
    async () => {
        try {
            const response = await axios
                .get(`${process.env.REACT_APP_URL}/api/cricket/markedMatchesClient`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const getCricketInfo = createAsyncThunk(
    'cricket/getCricketInfo',
    async () => {
        try {
            const response = await axios
                .get(`${process.env.REACT_APP_URL}/api/cricket/getCricketInfo`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const getUserBalance = createAsyncThunk(
    'cricket/getUserBalance',
    async (userId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/user/fetchUserBalance/${userId}`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);

export const userVerifyToken = createAsyncThunk(
    'cricket/userVerifyToken',
    async ({ token }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/user/verifyToken`, token);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);

export const getUserBets = createAsyncThunk(
    'cricket/getUserBets',
    async ({ userId, status, startDate, endDate }) => {
        try {
            const url = new URL(`${process.env.REACT_APP_URL}/api/cricket/getUserBets/${userId}`);

            if (status) url.searchParams.append('status', status);
            if (startDate && endDate) {
                url.searchParams.append('startDate', startDate);
                url.searchParams.append('endDate', endDate);
            }

            const response = await axios.get(url.toString());
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : error.message;
        }
    }
);
const initialState = {
    isLoading: false,
    cricketDetail: [],
    betMatches: [],
    winBets: [],
    lossBets: [],
    pendingBets: [],
    dateBets: [],
    cricketInfo: null,
    userBalance: 0,
    isError: false,
    errorMessage: '',
};

const cricketSlice = createSlice({
    name: "cricket",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCricketDetails.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = '';
            })
            .addCase(getCricketDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.cricketDetail = action.payload;
            })
            .addCase(getCricketDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.error.message;
            })

            .addCase(getUserBets.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserBets.fulfilled, (state, action) => {
                state.isLoading = false;
                const { status } = action.meta.arg;

                if (!status) {
                    state.betMatches = action.payload;
                } else if (status === 'win') {
                    state.winBets = action.payload;
                } else if (status === 'loss') {
                    state.lossBets = action.payload;
                } else if (status === 'pending') {
                    state.pendingBets = action.payload;
                } else {
                    state.dateBets = action.payload;
                }
            })
            .addCase(getUserBets.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.error.message;
            })

            .addCase(getCricketInfo.fulfilled, (state, action) => {
                state.cricketInfo = action.payload;
            })
            .addCase(getUserBalance.fulfilled, (state, action) => {
                state.userBalance = action.payload?.userBalance || 0;
            });
    },
});

export default cricketSlice.reducer;
