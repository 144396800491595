import React from "react";
import Result from "../../img/Result.png";

const MyMatch = ({ match }) => {
  const {
    teamA,
    teamB,
    league,
    question,
    choice,
    betAmount,
    result,
    winningAmount,
    matchDate,
    matchTime,
  } = match;

  return (
    <>
      <div className="p-0 rounded mb-3 shadow-lg bg-white">
        <div className="p-0 py-1 mx-2 border-bottom d-flex align-items-center">
          <div className="col d-flex align-items-center">
            <img
              src={teamA?.image}
              className="custom-img-35 rounded-circle"
              alt={teamA?.name}
            />
            <span className="fw-bold ps-1">{teamA?.code}</span>
          </div>

          <div className="col text-center fs-12">
            <p className="m-0 text-danger fw-bold text-smm-truncate">
              {league}
            </p>
            <i className="m-0 fw-bold text-secondary">V/S</i>
            <p className="m-0">
              {matchTime} {matchDate}
            </p>
          </div>

          <div className="col d-flex align-items-center justify-content-end">
            <span className="fw-bold pe-1">{teamB.code}</span>
            <img
              src={teamB.image}
              className="custom-img-35 rounded-circle"
              alt={teamB.name}
            />
          </div>
        </div>
        <div className="d-flex px-2 py-1">
          <div className="w-50 m-1">
            <h6 className="fs-12 fw-bold mb-1">Bet on</h6>
            <div className="rounded lightGrayBg fs-12 py-1 px-2">
              {question}
            </div>
          </div>
          <div className="w-25 m-1">
            <h6 className="fs-12 fw-bold mb-1">Your Bet</h6>
            <div className="rounded lightGrayBg fs-12 py-1 px-1">
              {" "}
              {choice === "teamA"
                ? teamA.name
                : choice === "teamB"
                ? teamB.name
                : choice}
            </div>
          </div>
          <div className="w-25 m-1">
            <h6 className="fs-12 fw-bold mb-1">Bet Amount</h6>
            <div className="rounded lightGrayBg fs-12 py-1 px-2">
              ₹{betAmount}
            </div>
          </div>
        </div>
        <div
          className={`rounded-bottom ${
            result === "win"
              ? "greenShadowBg"
              : result === "loss"
              ? "bg-danger"
              : "bg-secondary"
          } p-1 d-flex align-items-center`}
        >
          <div className="w-50 d-flex">
            <div className="w-smm-85 w-smm-100 bg-white py-1 ps-1 rounded">
              <img src={Result} alt="result" className="pe-1" />
              <span className="w-50 fs-12 fw-bolder">Result:</span>
              <span className="w-50 fs-12 ps-1">
                {result === "win"
                  ? choice === "teamA"
                    ? teamA.name
                    : choice === "teamB"
                    ? teamB.name
                    : choice
                  : result === "loss"
                  ? choice === "teamA"
                    ? teamB.name
                    : choice === "teamB"
                    ? teamA.name
                    : choice === "yes"
                    ? "No"
                    : choice === "no"
                    ? "Yes"
                    : "Pending..."
                  : "Pending"}
              </span>
            </div>
          </div>
          {result !== "pending" && (
            <div className="w-50 d-flex align-items-center gap-1 fs-12">
              <span className="fw-bolder text-white flex-fill text-end">
                {result === "win" ? (
                  <i className="bi bi-trophy-fill pe-1"></i>
                ) : (
                  ""
                )}

                {result === "win"
                  ? "You Won"
                  : result === "loss"
                  ? "You Loss"
                  : ""}
              </span>
              <span className="w-50 bg-white rounded p-1 text-center fw-bold">
                ₹{winningAmount}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyMatch;
