import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LiveMatch from "./components/LiveMatch";
import UpcomingMatch from "./components/UpcomingMatch";
import {
  getCricketDetails,
  getCricketInfo,
  getUserBalance,
} from "./redux/features/cricketSlice";
import { useDispatch, useSelector } from "react-redux";
import { Placeholder } from "react-bootstrap";
import Plus from "../img/Plus.png"
import Rupee from "../img/Rupee.png"

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);

  const { isLoading, cricketDetail, userBalance, cricketInfo } = useSelector(
    (state) => state.cricket
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % cricketInfo?.data?.bannerLogo.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [cricketInfo?.data?.bannerLogo.length]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const userId = "8376944575";

  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4Mzc2OTQ0NTc1IiwidXNlck5hbWUiOiI4Mzc2OTQ0NTc1IiwidXNlckVtYWlsIjoiODM3Njk0NDU3NUBnbWFpbC5jb20iLCJpYXQiOjE3MzE0MTEzMTl9.0ESWDfFoK_PTRXCzK1Pp0sfrJ4I2Q8AHvLna9sMbofo";

  useEffect(() => {

    if (cricketDetail.length === 0) {
      dispatch(getCricketDetails());
    }
    if (!cricketInfo) {
      dispatch(getCricketInfo());
    }
    if (!userBalance) {
      dispatch(getUserBalance(userId));
    }
    // dispatch(userVerifyToken(token));
  }, [dispatch]);

  return (
    <>

      <main className="shadow">
        <header className="header position-sticky px-4 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center ">
            <i
              onClick={() => navigate("/")}
              className="bi bi-arrow-left-circle me-2 fs-4"
              type="button"
            ></i>
            <h5 className="m-0 fw-bolder py-3">Home Page</h5>
          </div>
          <div className="header-aside bg-white border rounded-pill text-black p-1 d-flex justify-content-between align-items-center">
            <img src={Rupee} alt="rupee" className="pe-1" />
            <h5 className="m-0">{Number(userBalance.toFixed()).toLocaleString("en-IN")}</h5>
            <img src={Plus} alt="plus" className="ps-1" type="button" />
          </div>
        </header>
        <div className="mx-smm-2 mx-smm-3 pt-3 pb-5">
          {isLoading ?
            <div className="placeholder-glow">
              <img src="" className="placeholder w-100 rounded h-180" alt="..." /></div>
            :
            <img
              src={cricketInfo?.data?.bannerLogo[currentIndex]}
              className="w-100 h-180 rounded slider__item"
              alt="Slider"
            />
          }
          <div className="d-flex justify-content-center align-items-center my-2">
            {cricketInfo?.data?.bannerLogo.map((_, index) => (
              <span
                key={index}
                className={`rounded mx-1 ${currentIndex === index ? "active" : "dot"
                  }`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
          <div className="notification text-white mt-3 d-flex justify-content-between align-items-center border rounded-pill">
            {/* <div className="d-flex flex-fill justify-content-between align-items-center"> */}
            <i className="bi bi-bell-fill px-2 fs-5"></i>
            <p className="m-0 fs-12 lh-sm">
              {cricketInfo?.data?.notificationText}
            </p>
            <a href={cricketInfo?.data?.notificationLink} target="blank" type="button" className="text-decoration-none redShadowBg text-white d-flex p-2 px-3 justify-content-between align-items-center border rounded-pill fw-bold">
              <i className="bi bi-fire pe-1"></i>
              Detail
            </a>
            {/* </div> */}
          </div>
          <div className="mt-4 border-top border-bottom pt-2">
            <h6 className="fw-bolder">Live Matches</h6>
            {isLoading ? (
              <div className="card-text placeholder-glow border rounded-3">
                <span className="placeholder col-12 py-3 rounded-top-3 "></span>
                <div className="m-3">
                  <span className="placeholder col-1 py-3 rounded-circle"></span>
                  <span className="placeholder placeholder-xs col-2 ms-1"></span>
                  <span className="placeholder placeholder-sm col-1 mx-3"></span>
                  <span className="placeholder placeholder-xs col-2 me-1"></span>
                  <span className="placeholder col-1 py-3 rounded-circle"></span>
                  <span className="placeholder col-3 py-2 rounded-pill ms-3"></span>
                </div>
                <span className="placeholder col-8 py-3"></span>
              </div>
            ) : cricketDetail?.data?.liveMatches?.length > 0 ? (
              cricketDetail?.data?.liveMatches?.map((match) => (
                <div key={match.matchId}>
                  <LiveMatch match={match} />
                </div>
              ))
            ) : (
              <h6 className="p-3 text-danger fw-bold bg-backgroundImg border rounded py-4">
                There is no Live Match Currently
              </h6>
            )}
          </div>
          <div className="mt-3">
            <h6 className="my-3 fw-bolder">Upcoming Matches</h6>
            {isLoading ? (
              Array.from({ length: 2 }).map((_, index) => (
                <Placeholder as="p" animation="glow" key={index}>
                  <Placeholder xs={12} className="h-15 rounded" />
                </Placeholder>
              ))
            ) : (
              <UpcomingMatch
                upComingMatchData={cricketDetail?.data?.upcomingMatches}
              />
            )}
          </div>
        </div>
        <footer className="footer d-flex justify-content-center">
          <button
            className="footerButton rounded-pill my-2 p-2 text-white fw-bold"
            onClick={() => navigate("/my-matches")}
          >
            My Matches
          </button>
        </footer>
      </main>

    </>
  );
};

export default Home;
