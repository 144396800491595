import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MatchQuestion from "./components/MatchQuestion";
import { useSelector } from "react-redux";

const JoinMatch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { liveScoress, liveQuestions, closeOrOpenBet } = useSelector(
    (state) => state.webSocket
  );
  const [isBetOverByOver, setIsBetOverByOver] = useState(false)
  const liveScores = [
    {
      matchId: 64653,
      round: "1st T20I",
      league: "Twenty20 International",
      localTeam: {
        id: 36,
        code: "AUS",
        name: "Australia",
        image: "https://cdn.sportmonks.com/images/cricket/teams/4/36.png",
      },
      visitorTeam: {
        id: 1,
        code: "PAK",
        name: "Pakistan",
        image: "https://cdn.sportmonks.com/images/cricket/teams/1/1.png",
      },
      tossWon: {
        id: 1,
        code: "PAK",
        name: "Pakistan",
        image: "https://cdn.sportmonks.com/images/cricket/teams/1/1.png",
      },
      winnerTeam: null,
      draw: null,
      status: "1st Innings",
      elected: "bowling",
      superOver: false,
      live: true,
      totalOvers: 7,
      game_type: "T20I",
      date: "2024-11-14",
      time: "01:30 PM",
      starting_at: "14-11-2024",
      runs: [
        {
          matchId: 64653,
          battingTeam: {
            id: 36,
            code: "AUS",
            name: "Australia",
            image: "https://cdn.sportmonks.com/images/cricket/teams/4/36.png",
          },
          score: 34,
          inning: 1,
          wickets: 1,
          overs: 2.2,
          pp1: "1-2",
          pp2: null,
          pp3: null,
          type: "run",
        },
      ],
      batting: [
        {
          player: {
            name: "Matthew Short",
            image:
              "https://cdn.sportmonks.com/images/cricket/players/13/397.png",
            playerId: 397,
          },
          matchId: 64653,
          playerTeam: {
            id: 36,
            code: "AUS",
            name: "Australia",
            image: "https://cdn.sportmonks.com/images/cricket/teams/4/36.png",
          },
          score: 7,
          active: false,
          ball: 3,
          fours: 0,
          sixes: 1,
          fowScore: 0,
          fowBalls: 0,
          Sr: 233,
          type: "batting",
        },
        {
          player: {
            name: "Glenn Maxwell",
            image: "https://cdn.sportmonks.com/images/cricket/players/8/40.png",
            playerId: 40,
          },
          matchId: 64653,
          playerTeam: {
            id: 36,
            code: "AUS",
            name: "Australia",
            image: "https://cdn.sportmonks.com/images/cricket/teams/4/36.png",
          },
          score: 16,
          active: true,
          ball: 7,
          fours: 4,
          sixes: 0,
          fowScore: 0,
          fowBalls: 0,
          Sr: 229,
          type: "batting",
        },
      ],
      bowling: {
        matchId: 64653,
        battingTeam: {
          id: 36,
          code: "AUS",
          name: "Australia",
          image: "https://cdn.sportmonks.com/images/cricket/teams/4/36.png",
        },
        strikerBatsMan: {
          name: "Matthew Short",
          image: "https://cdn.sportmonks.com/images/cricket/players/13/397.png",
          playerId: 397,
        },
        nonSrikerBatsMan: {
          name: "Glenn Maxwell",
          image: "https://cdn.sportmonks.com/images/cricket/players/8/40.png",
          playerId: 40,
        },
        scoreName: "No Run",
        runs: 0,
        four: true,
        six: false,
        bye: 0,
        legBye: 0,
        noBall: 0,
        noBallRuns: 0,
        isWicket: false,
        ball: true,
        out: false,
        type: "bowling",
      },
    },
  ];
  // const [liveQuestions, setLiveQuestions] = useState([
  //   {
  //     question: {
  //       questionId: 1,
  //       question: "Who will win the toss?",
  //       isCheckedByAdmin: false,
  //       possibility: {
  //         teamA: {
  //           worst: null,
  //           best: null,
  //         },
  //         teamB: {
  //           worst: null,
  //           best: null,
  //         },
  //       },
  //     },
  //     matchId: 62719,
  //   },
  //   {
  //     question: {
  //       questionId: 3,
  //       question: "Who will win the match?",
  //       isCheckedByAdmin: false,
  //       possibility: {
  //         teamA: {
  //           worst: null,
  //           best: null,
  //         },
  //         teamB: {
  //           worst: null,
  //           best: null,
  //         },
  //       },
  //     },
  //     matchId: 62719,
  //   },
  //   {
  //     question: {
  //       questionId: 2,
  //       question: "Who will bat first?",
  //       isCheckedByAdmin: false,
  //       possibility: {
  //         teamA: {
  //           worst: null,
  //           best: null,
  //         },
  //         teamB: {
  //           worst: null,
  //           best: null,
  //         },
  //       },
  //     },
  //     matchId: 62719,
  //   },
  // ]);
  const obj = {
    runs: 0,
    four: false,
    six: false,
    bye: 0,
    legBye: 0,
    noBall: 0,
    noBallRuns: 0,
    isWicket: false,
    ball: true,
    out: false,
  };

  function getRunByBall() {
    switch (true) {
      case obj.runs:
        return obj.runs;
      case obj.four:
        return 4;
      case obj.six:
        return 6;
      case obj.bye:
        return obj.bye;
      case obj.legBye:
        return obj.legBye + "lb";
      case obj.noBall:
        return obj.noBall + "nb";
      case obj.noBallRuns:
        return obj.noBallRuns;
      case obj.isWicket:
        return "W";
      default:
        return 0;
    }
  }

  useEffect(() => {
    if (liveQuestions && liveQuestions.some((que) => que.question.questionId > 7)) {
      setIsBetOverByOver(true);
    }
  }, []);

  if (location.state?.match.matchId === closeOrOpenBet?.matchId && !closeOrOpenBet?.status) {
    return navigate("/home")
  }

  return (
    <>
      <main className="shadow">
        <header className="header position-sticky">
          <div className="">
            <div className="d-flex align-items-center px-3">
              <i
                onClick={() => navigate("/home")}
                className="bi bi-arrow-left-circle me-2 fs-3"
                type="button"
              ></i>
              <h5 className="m-0 fw-bolder flex-grow-1 text-center flex-fill py-3">
                Select a Contest to Join
              </h5>
            </div>
            {liveScores?.map(
              (match) =>
                match.matchId === location.state?.match.matchId && (
                  <div
                    className="lightGrayBg text-black shadow"
                    key={match.matchId}
                  >
                    <div className="d-flex justify-content-between align-items-center mx-3 pt-1">
                      <div className="mb-1">
                        <img
                          src={match.localTeam.image}
                          alt={match.localTeam.name}
                          className="custom-img rounded-circle"
                        />
                      </div>
                      <div className="text-center">
                        <div className="d-flex justify-content-between">
                          <div className="fw-bold pe-2 text-nowrap ">
                            {match.localTeam.name}
                          </div>
                          <i className="m-0 fw-bold text-secondary">V/S</i>
                          <div className="fw-bold ps-2 text-nowrap">
                            {match.visitorTeam.name}
                          </div>
                        </div>
                      </div>

                      <div className="text-end mb-1">
                        <img
                          src={match.visitorTeam.image}
                          alt={match.visitorTeam.name}
                          className="custom-img rounded-circle"
                        />
                      </div>
                    </div>
                    {match.runs?.map((runDetails) => {
                      const isLocalTeamBatting =
                        runDetails.battingTeam.id === match.localTeam.id;
                      const isVisitorTeamBatting =
                        runDetails.battingTeam.id === match.visitorTeam.id;

                      return (
                        <div
                          className="d-flex justify-content-between ms-2 me-3"
                          key={runDetails.matchId}
                        >
                          <div className="w-50 text-start">
                            <p className="row m-0">
                              <span className="col-4 p-0 fw-bold text-danger">
                                Runs
                              </span>
                              <span className="col-2 p-0 text-center">:</span>
                              <span className="col-6 p-0">
                                {isLocalTeamBatting
                                  ? `${runDetails.score}/${runDetails.wickets}`
                                  : "-"}
                              </span>
                            </p>
                            <p className="row m-0">
                              <span className="col-4 p-0 fw-bold text-danger">
                                Overs
                              </span>
                              <span className="col-2 p-0 text-center">:</span>
                              <span className="col-6 p-0">
                                {isLocalTeamBatting ? runDetails.overs : "-"}
                              </span>
                            </p>
                          </div>
                          <div className="w-50 text-center">
                            {/* <div className="text-truncate">
                              {match.tossWon.name} won Toss
                            </div>
                            <div>Chose {match.elected}</div> */}
                            <div className="d-flex justify-content-center">
                              <div className="w-75 border border-danger rounded-pill px-1 pt-1 bg-danger" />
                            </div>
                            <div>{match.status}</div>
                          </div>

                          <div className="w-50 text-end">
                            <p className="row m-0 justify-content-end">
                              <span className="col-6 p-0 text-end">
                                {isVisitorTeamBatting
                                  ? `${runDetails.score}/${runDetails.wickets}`
                                  : "-"}
                              </span>
                              <span className="col-2 p-0 text-center">:</span>
                              <span className="col-4 p-0 fw-bold text-danger">
                                Runs
                              </span>
                            </p>
                            <p className="row m-0 justify-content-end">
                              <span className="col-6 p-0 text-end">
                                {isVisitorTeamBatting ? runDetails.overs : "-"}
                              </span>
                              <span className="col-2 p-0 text-center">:</span>
                              <span className="col-4 p-0 fw-bold text-danger">
                                Overs
                              </span>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                    <div className="mx-2 border-top border-bottom border-dark">
                      <table className="table m-0 mt-1 fs-12">
                        <thead>
                          <tr className=" border fw-bold">
                            <th className="fw-bold text-white p-0 ps-3 redShadowBg2 rounded-start">
                              BATSMAN
                            </th>
                            {["B", "R", "4s", "6s", "SR"].map((val, index, arr) => (
                              <th
                                key={index}
                                className={`fw-bold text-white text-center p-0 px-3 redShadowBg2 ${index === arr.length - 1 && "rounded-end"
                                  }`}
                              >
                                {val}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {match.batting?.map((Player) => {
                            const { player, ball, score, fours, sixes, Sr } =
                              Player;
                            const isStrikerBatsman =
                              match.bowling.strikerBatsMan.playerId ===
                              player.playerId;
                            return (
                              <tr key={player?.playerId}>
                                <td className="fw-bold p-0 ps-3 text-secondary">
                                  {player?.name}
                                  {isStrikerBatsman && (
                                    <i className="bi bi-record2 ps-1"></i>
                                  )}{" "}
                                </td>
                                {[ball, score, fours, sixes, Sr].map(
                                  (val, index) => (
                                    <td
                                      key={index}
                                      className="text-center p-0 text-secondary"
                                    >
                                      {val}
                                    </td>
                                  )
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-between align-items-center text-white text-center py-1 mx-2">
                      <div className="fs-12">
                        <span className="px-1 py-1 text-black border rounded-start bg-warning text-truncate">
                          Current Over
                        </span>
                        <span className="px-1 py-1 greenShadowBg">
                          {match.runs.map(
                            (matchRun) =>
                              // matchRun.matchId == match.matchId &&
                              matchRun.overs
                          )}
                        </span>
                      </div>
                      {new Array(6)
                        .fill("")
                        .map((val, index) => (
                          <span
                            key={index}
                            className={`py-1 px-2 border border-dark rounded-circle d-flex justify-content-center align-items-center ${val === ""
                              ? ""
                              : val === "w" || val === "lbw"
                                ? "bg-danger"
                                : "greenShadowBg"
                              } scoreCircle`}
                          >
                            {val}
                          </span>
                        ))}
                      {/* {Array.from({ length: 6 + obj.noBall }).map(
                    (val, index, arr) => (
                      <span
                        key={index}
                        className={`py-1 px-2 border border-dark rounded-circle d-flex justify-content-center align-items-center ${val === ""
                          ? ""
                          : val === "w" || val === "lbw"
                            ? "bg-danger"
                            : "greenShadowBg"
                          } scoreCircle`}
                      >
                      
                        {getRunByBall()}
                      </span>
                    )
                  )} */}
                      {/* {new Array(6 + obj.noBall).fill("").map((val, index) => (
                    <span
                      key={index}
                      className={`py-1 px-2 border border-dark rounded-circle d-flex justify-content-center align-items-center ${
                        val === ""
                          ? ""
                          : val === "w" || val === "lbw"
                          ? "bg-danger"
                          : "greenShadowBg"
                      } scoreCircle`}
                    >
                      {val}
                    </span>
                  ))} */}
                    </div>
                  </div>
                )
            )}
          </div>
        </header>
        <div className="mx-smm-2 mx-smm-3 py-3">
          <h4>Select a contest to join</h4>
          {liveQuestions?.map(
            (que, index) =>
              que?.question.questionId < 8 && (
                < MatchQuestion
                  match={que}
                  key={index}
                  teamA={location.state?.match.team_a}
                  teamB={location.state?.match.team_b}
                  match_id={location.state?.match.matchId}
                  league={location.state?.match.league}
                  battingTeam={
                    liveScores?.find((match) => {
                      return match?.matchId === location.state?.match?.matchId;
                    })?.bowling?.battingTeam.id}
                  date={location.state?.match?.date}
                  time={location.state?.match?.time}
                />
              )
          )}
          {/* {liveQuestions.length > 7 && ( */}
          {isBetOverByOver &&
            <div className="border-top border-start border-end rounded mb-3">
              {" "}
              <h6 className="text-center text-white fw-bolder rounded-top bg-primary py-3">
                BET OVER BY OVER
              </h6>
              <div className="px-3">
                {liveQuestions?.map((match, index) => (
                  <div key={index}>
                    {match.question.questionId > 7 && (
                      <MatchQuestion
                        match={match}
                        betOverByOver={true}
                        match_id={location.state?.match.matchId}
                        league={location.state?.match.league}
                        battingTeam={
                          liveScores?.find((match) => {
                            return match?.matchId === location.state?.match?.matchId;
                          })?.bowling?.battingTeam.id}
                        date={location.state?.match?.date}
                        time={location.state?.match?.time}
                      />
                    )}
                  </div>
                ))}{" "}
              </div>
            </div>
          }
          {/* )} */}
        </div>
      </main>
    </>
  );
};

export default JoinMatch;
