import React, { useEffect, useState } from "react";
import WhiteBall from "../../img/WhiteBall.png";

const UpcomingMatch = ({ upComingMatchData }) => {
  const [timeLeft, setTimeLeft] = useState("");

  function calculateTimeLeft(timeOfMatch, date) {
    const currentDate = new Date();

    const [time, period] = timeOfMatch?.split(" ");
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    const [year, month, day] = date.split("-");
    const matchDate = new Date(year, month - 1, day, hours, minutes, 0);

    const currentDateWithoutTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const matchDateWithoutTime = new Date(
      matchDate.getFullYear(),
      matchDate.getMonth(),
      matchDate.getDate()
    );

    if (matchDateWithoutTime > currentDateWithoutTime) {
      return new Intl.DateTimeFormat("en-IN", {
        dateStyle: "medium",
      }).format(matchDate);
    }

    const timeDifference = matchDate - currentDate;

    if (timeDifference <= 0) {
      return "Match already started or passed.";
    }

    const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesLeft = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const secondsLeft = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `${hoursLeft}h ${minutesLeft}m ${secondsLeft}s`;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTime = upComingMatchData?.map((match) =>
        calculateTimeLeft(match.time, match.date)
      );
      setTimeLeft(updatedTime);
    }, 1000);

    return () => clearInterval(timer);
  }, [upComingMatchData]);

  return (
    <>
      {upComingMatchData?.length > 0 &&
        upComingMatchData?.map((match, i) => (
          <div
            key={`${match._id}-${i}`}
            className="p-0 border rounded mb-3 bg-backgroundImg shadow-sm"
          >
            <div className="redShadowBg rounded-top-3 text-white p-0 d-flex align-items-center justify-content-between py-1">
              <div className="m-0 fw-bold fs-smm-12 d-flex align-items-center">
                <img src={WhiteBall} alt="ball" className="px-1 text-white" />
                {match.league}
                <span className="fs-12 fw-bolder ps-1">{`(${match.round})`}</span>
              </div>
              {/* <i className="bi bi-bell-fill px-2 fs-3"></i> */}
            </div>
            <div className="p-0 py-1 d-flex justify-content-between align-items-center">
              <div className="w-50 d-flex align-items-center px-2">
                <img
                  src={match.team_a?.image}
                  className="custom-img-35 rounded-circle"
                  alt={match.team_a?.name}
                />
                <span className="fw-bold ps-1">{match.team_a?.code}</span>
              </div>
              <div className="w-50 text-center">
                <i className="m-0 fw-bold text-secondary">V/S</i>
                <p className="m-0 text-danger fw-bold">
                  {calculateTimeLeft(match.time, match.date)}
                </p>
                <p className="m-0">{match.time}</p>
              </div>
              <div className="w-50 d-flex align-items-end  px-2">
                <span className="fw-bold pe-1 flex-fill text-end">
                  {match.team_b?.code}
                </span>
                <img
                  src={match.team_b?.image}
                  className="custom-img-35 rounded-circle"
                  alt={match.team_b?.name}
                />
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default UpcomingMatch;
