import React, { useState } from "react";
import vector from "../../img/Vector.png";
import decline from "../../img/false-7067870_1280.png";
import { useSelector } from "react-redux";
import WhiteBall from "../../img/WhiteBall.png";
import Result from "../../img/Result.png";

const MatchQuestion = ({
  match,
  betOverByOver,
  teamA,
  teamB,
  match_id,
  league,
  battingTeam,
  date,
  time,
}) => {
  const { questionId, question, worst, isLocked } = match?.question || {};
  const { socket, totalBetJoin } = useSelector((state) => state.webSocket);

  const [selectBetModel, setSelectBetModel] = useState(false);
  const [betConfirmedModel, setBetConfirmedModel] = useState(false);
  const [betErrorModel, setBetErrorModel] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(100);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const callWsQue = (que) => {
    setSelectBetModel(false);
    const ws = new WebSocket(socket);

    ws.onopen = () => {
      console.log("WebSocket connection established");
      const subscribeMessage = {
        gameType: "cricket",
        type: "CRICKET_PLACE_BET",
        data: {
          userId: "8376944575",
          question: que,
          matchId: match_id,
          betAmount: selectedAmount,
          choice: selectedChoice,
          league: league,
          teams: [teamA?.teamId, teamB?.teamId],
          battingTeam: battingTeam || 0,
          matchDate: date,
          matchTime: time,
        },
      };

      ws.send(JSON.stringify(subscribeMessage));
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);

      console.log("Received live-score:", message);
      if (message.type === "BET_SUCCESS") {
        setBetConfirmedModel(true);
        setInterval(() => {
          setBetConfirmedModel(false);
        }, 3000);
      }
      if (message.type === "ERROR") {
        setBetErrorModel(true);
        setErrorMessage(message?.message);
        setInterval(() => {
          setBetErrorModel(false);
        }, 3000);
      }
    };
  };

  return (
    <div
      className={`p-0 border rounded mb-3 bg-backgroundImg shadow-sm ${
        isLocked && "disabledDiv"
      }`}
    >
      <div
        className={`${
          betOverByOver ? "bg-primary" : "redShadowBg"
        } border rounded-top-3 text-white p-0 d-flex align-items-center justify-content-between`}
      >
        <h6 className="m-0 fw-bold d-flex align-items-center py-1">
          <img src={WhiteBall} alt="ball" className="px-1 text-white" />
          {question}
        </h6>
        {/* <i
          className={`bi bi-bell-fill
             ${worst ? "text-warning" : ""} 
            px-2 fs-3`}
        ></i> */}
      </div>
      <div className="p-0 py-1 d-flex align-items-center">
        <div className="flex-fill d-flex align-items-center justify-content-center px-smm-1 py-2 border ms-1">
          {questionId < 4 ? (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={teamA?.image}
                  alt={teamA?.name}
                  className="custom-img-35 rounded-circle"
                />
                <span className="fw-bold ps-1">{teamA?.code}</span>
              </div>
              <div className="flex-fill text-end pe-1">
                <button
                  onClick={() => {
                    setSelectBetModel(true);
                    setSelectedChoice("teamA");
                  }}
                  className={`border rounded-pill py-1 pe-2 ${
                    isLocked ? "bg-secondary" : "greenShadowBg"
                  } text-white fw-bold`}
                  disabled={isLocked}
                >
                  Bet <i className="bi bi-chevron-right"></i>
                </button>
              </div>
            </>
          ) : (
            <button
              onClick={() => {
                setSelectBetModel(true);
                setSelectedChoice("yes");
              }}
              className={`border rounded-pill py-1 px-5 ${
                isLocked ? "bg-secondary" : "greenShadowBg"
              } text-white m-0 fw-bold`}
              disabled={isLocked}
            >
              Yes
            </button>
          )}
        </div>
        <div className="flex-fill d-flex align-items-center justify-content-center px-smm-1 py-2 border mx-1">
          {questionId < 4 ? (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={teamB?.image}
                  alt={teamB?.name}
                  className="custom-img-35 rounded-circle"
                />
                <span className="fw-bold ps-1">{teamB?.code}</span>
              </div>
              <div className="flex-fill text-end pe-1">
                <button
                  onClick={() => {
                    setSelectBetModel(true);
                    setSelectedChoice("teamB");
                  }}
                  className={`border rounded-pill py-1 pe-2 ${
                    isLocked ? "bg-secondary" : "greenShadowBg"
                  } text-white fw-bold`}
                  disabled={isLocked}
                >
                  Bet <i className="bi bi-chevron-right"></i>
                </button>
              </div>
            </>
          ) : (
            <button
              onClick={() => {
                setSelectBetModel(true);
                setSelectedChoice("no");
              }}
              className={`border rounded-pill py-1 px-5 ${
                isLocked ? "bg-secondary" : "greenShadowBg"
              } text-white m-0 fw-bold`}
              disabled={isLocked}
            >
              No
            </button>
          )}
        </div>
      </div>
      {!worst && (
        <div className="fs-12 mx-2">
          <p className="m-0">
            <span className="fw-bold">Yes : </span>
            <span> {}</span>
            <span className="text-success ps-2">170%-200% </span>
          </p>
          <p className="m-0">
            <span className="fw-bold">No : </span>
            <span> {}</span>
            <span className="text-success ps-2">150%-200%</span>
          </p>
        </div>
      )}
      <div
        className={`p-0 d-flex align-items-center justify-content-between ${
          betOverByOver ? "skyBlueBg" : "lightYellowBg"
        } `}
      >
        <div className="py-1 fs-12">
          <i className="bi bi-trophy px-1 text-danger"></i>{" "}
          {totalBetJoin?.find(
            (betJoin) =>
              betJoin?.questionId === questionId &&
              betJoin?.matchId === match_id &&
              betJoin?.totalBets
          )?.totalBets || 0}
          {""} People Joined
        </div>
        <div className="w-50 d-flex justify-content-end">
          <div className="w-60 d-flex border rounded bg-white ps-1 me-2">
            <img src={Result} alt="result" className="pe-1" />
            <span className="w-50 fs-12 fw-bold">Result:</span>
            <span className="w-25 text-end ps-2">{}</span>
          </div>
        </div>
      </div>

      {selectBetModel && (
        <>
          <div
            className="modal-backdrop fade show"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1040,
            }}
          ></div>
          <div
            className="modal show"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              zIndex: 1050,
              left: 0,
              top: 0,
              height: "100%",
              overflow: "hidden",
            }}
          >
            <div className="modal-dialog modal-dialog-centered mx-2 w-380">
              <div className="modal-content">
                <div className="modal-header py-2 d-flex justify-content-between modelHeader text-white">
                  <h5 className="modal-title flex-fill text-center fw-bolder">
                    Select Betting Amount
                  </h5>
                  <i
                    onClick={() => setSelectBetModel(false)}
                    className="bi bi-x-circle fs-4 fw-bold"
                  ></i>
                </div>

                <div className="modal-body pt-0">
                  <div className="row p-0">
                    {[
                      100, 150, 200, 300, 400, 500, 600, 800, 1000, 2000, 5000,
                      10000,
                    ].map((amount, index) => (
                      <div key={index} className="col-4 text-center my-3">
                        <div
                          onClick={() => setSelectedAmount(amount)}
                          className={`${
                            amount === selectedAmount
                              ? "bg-secondary"
                              : "greenShadowBg"
                          } text-white rounded-pill fw-bold py-1 cursor-pointer`}
                        >
                          &#x20B9;{amount.toLocaleString("en-IN")}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="border border-danger rounded my-2">
                    <h5 className="modelHeader p-2 text-center w-100 fw-bolder rounded-top text-white">
                      Winning Amount
                    </h5>
                    <div className="d-flex justify-content-between">
                      <div className="w-50 mx-2">
                        <div className="fs-12 fw-bolder">
                          <i className="bi bi-database-fill text-success fs-5"></i>{" "}
                          Betting Amount
                        </div>
                      </div>
                      <div className="w-50 mx-2">
                        <div className="fs-12 fw-bolder">
                          <i className="bi bi-database-fill text-success fs-5"></i>{" "}
                          Winning Amount Upto
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <div className="w-50 m-2 border border-danger rounded d-flex align-items-center fw-bold">
                        <span className="modelHeader text-white p-2 rounded-start">
                          &#x20B9;
                        </span>
                        {/* <span className="flex-fill text-center">
                          {selectedAmount.toLocaleString("en-IN")}
                        </span> */}
                        <input
                          type="number"
                          value={selectedAmount}
                          onChange={(e) =>
                            setSelectedAmount(parseFloat(e.target.value || 0))
                          }
                          className="w-100 text-center border-0 fw-bold"
                          min={100}
                        />
                      </div>
                      <div className="w-50 m-2 border border-danger rounded d-flex align-items-center fw-bold">
                        <span className="modelHeader text-white p-2 rounded-start">
                          &#x20B9;
                        </span>
                        <span className="flex-fill text-center border-0">{`${
                          Number(selectedAmount) + 1
                        }-${Number(selectedAmount * (70 / 100) + selectedAmount)
                          .toFixed()
                          .toLocaleString("en-IN")}`}</span>
                      </div>
                    </div>
                  </div>
                  {/* <button
                    className="text-white fs-5 fw-bold redShadowBg border rounded-pill py-1 px-4"
                    onClick={() => callWsQue(match?.question)}
                  >
                    Confirm Bet
                  </button> */}
                  {selectedAmount < 100 && (
                    <div className="fs-12 text-danger">
                      Betting amount should be more than ₹99
                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-center pt-3">
                    <button
                      className={`text-white fs-5 fw-bold redShadowBg border rounded-pill py-1 px-4 ${
                        selectedAmount < 100 ? "disabledDiv" : ""
                      }`}
                      onClick={() => callWsQue(match?.question)}
                      disabled={selectedAmount < 100}
                    >
                      Confirm Bet
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {betConfirmedModel && (
        <>
          <div
            className="modal-backdrop fade show"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1040,
            }}
          ></div>
          <div
            className="modal show"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              zIndex: 1050,
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <div className="w-100 modal-dialog modal-dialog-centered mx-2">
              <div className="modal-content">
                <div className="modal-header py-2 d-flex justify-content-between modelHeader text-white">
                  <h3 className="modal-title flex-fill text-center fw-bolder">
                    Bet Confirmed
                  </h3>
                  <i
                    onClick={() => {
                      setBetConfirmedModel(false);
                    }}
                    className="bi bi-x-circle fs-2"
                    style={{ cursor: "pointer" }}
                    aria-label="Close"
                  ></i>
                </div>
                <div className="modal-body p-0 pt-3 text-center mb-3">
                  <img src={vector} alt="vector" className="my-3" />
                  <h1 className="text-success fw-bolder my-4">
                    Your Bet Placed
                  </h1>
                  <p className="m-0">Betting Amount</p>
                  <h3 className="fw-bolder lightGrayBg rounded lightGrayBg mx-5 py-2 my-3">
                    ₹{selectedAmount.toLocaleString("en-IN")}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {betErrorModel && (
        <>
          <div
            className="modal-backdrop fade show"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1040,
            }}
          ></div>
          <div
            className="modal show"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              zIndex: 1050,
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <div className="w-100 modal-dialog modal-dialog-centered mx-2">
              <div className="modal-content">
                <div className="modal-header py-2 d-flex justify-content-between modelHeader text-white">
                  <h3 className="modal-title flex-fill text-center fw-bolder">
                    Bet Decline
                  </h3>
                  <i
                    onClick={() => {
                      setBetErrorModel(false);
                    }}
                    className="bi bi-x-circle fs-2"
                    aria-label="Close"
                  ></i>
                </div>
                <div className="modal-body p-0 pt-3 text-center mb-3">
                  <img src={decline} alt="decline" className="my-3 w-50 h-50" />
                  <h1 className="text-danger fw-bolder my-4">{errorMessage}</h1>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MatchQuestion;
