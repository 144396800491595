import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Ball from "../../img/Ball.png";

const LiveMatch = ({ match }) => {
  const navigate = useNavigate();
  const [openForBetByState, setOpenForBetByState] = useState(
    match.isOpenForBet
  );
  const { closeOrOpenBet, liveScoress } = useSelector(
    (state) => state.webSocket
  );
  const liveScores = [
    {
      matchId: 64653,
      round: "1st T20I",
      league: "Twenty20 International",
      localTeam: {
        id: 36,
        code: "AUS",
        name: "Australia",
        image: "https://cdn.sportmonks.com/images/cricket/teams/4/36.png",
      },
      visitorTeam: {
        id: 1,
        code: "PAK",
        name: "Pakistan",
        image: "https://cdn.sportmonks.com/images/cricket/teams/1/1.png",
      },
      tossWon: {
        id: 1,
        code: "PAK",
        name: "Pakistan",
        image: "https://cdn.sportmonks.com/images/cricket/teams/1/1.png",
      },
      winnerTeam: null,
      draw: null,
      status: "1st Innings",
      elected: "bowling",
      superOver: false,
      live: true,
      totalOvers: 7,
      game_type: "T20I",
      date: "2024-11-14",
      time: "01:30 PM",
      starting_at: "14-11-2024",
      runs: [
        {
          matchId: 64653,
          battingTeam: {
            id: 36,
            code: "AUS",
            name: "Australia",
            image: "https://cdn.sportmonks.com/images/cricket/teams/4/36.png",
          },
          score: 34,
          inning: 1,
          wickets: 1,
          overs: 2.2,
          pp1: "1-2",
          pp2: null,
          pp3: null,
          type: "run",
        },
      ],
      batting: [
        {
          player: {
            name: "Matthew Short",
            image:
              "https://cdn.sportmonks.com/images/cricket/players/13/397.png",
            playerId: 397,
          },
          matchId: 64653,
          playerTeam: {
            id: 36,
            code: "AUS",
            name: "Australia",
            image: "https://cdn.sportmonks.com/images/cricket/teams/4/36.png",
          },
          score: 7,
          active: false,
          ball: 3,
          fours: 0,
          sixes: 1,
          fowScore: 0,
          fowBalls: 0,
          Sr: 233,
          type: "batting",
        },
        {
          player: {
            name: "Glenn Maxwell",
            image: "https://cdn.sportmonks.com/images/cricket/players/8/40.png",
            playerId: 40,
          },
          matchId: 64653,
          playerTeam: {
            id: 36,
            code: "AUS",
            name: "Australia",
            image: "https://cdn.sportmonks.com/images/cricket/teams/4/36.png",
          },
          score: 16,
          active: true,
          ball: 7,
          fours: 4,
          sixes: 0,
          fowScore: 0,
          fowBalls: 0,
          Sr: 229,
          type: "batting",
        },
      ],
      bowling: {
        matchId: 64653,
        battingTeam: {
          id: 36,
          code: "AUS",
          name: "Australia",
          image: "https://cdn.sportmonks.com/images/cricket/teams/4/36.png",
        },
        strikerBatsMan: {
          name: "Matthew Short",
          image: "https://cdn.sportmonks.com/images/cricket/players/13/397.png",
          playerId: 397,
        },
        nonSrikerBatsMan: {
          name: "Glenn Maxwell",
          image: "https://cdn.sportmonks.com/images/cricket/players/8/40.png",
          playerId: 40,
        },
        scoreName: "No Run",
        runs: 0,
        four: true,
        six: false,
        bye: 0,
        legBye: 0,
        noBall: 0,
        noBallRuns: 0,
        isWicket: false,
        ball: true,
        out: false,
        type: "bowling",
      },
    },
  ];

  useEffect(() => {
    if (closeOrOpenBet?.matchId === match?.matchId) {
      setOpenForBetByState(false);
    }
  }, []);

  return (
    <>
      <div className="my-3 border rounded bg-backgroundImg shadow-sm">
        <div className="row m-0">
          <div className="col-5 border rounded-top-3 rounded-end-0 bg-warning p-0 d-flex align-items-center justify-content-center">
            <div className="m-0 d-flex flex-fill align-items-center">
              <img src={Ball} alt="ball" className="px-1" />
              {match.type}{" "}
              <span className="fs-12 ps-1">{`(${match.round})`}</span>
            </div>
          </div>
          <div className="col-5 p-0 redShadowBg d-flex align-items-center">
            {liveScores?.map((matchDetail) =>
              matchDetail.runs?.map(
                (runDetail) =>
                  match.matchId === runDetail.matchId && (
                    <div
                      key={runDetail.matchId}
                      className="d-flex justify-content-around align-items-stretch text-center text-white flex-fill"
                    >
                      <div className="px-1">
                        <p className="m-0 fs-12 fw-bold">Runs</p>
                        <h6 className="m-0 fw-bolder">
                          {runDetail.score || 0}
                        </h6>
                      </div>
                      <div className="px-1">
                        <p className="m-0 fs-12 fw-bold">Wickets</p>
                        <h6 className="m-0 fw-bolder">
                          {runDetail.wickets || 0}
                        </h6>
                      </div>
                      <div className="px-1">
                        <p className="m-0 fs-12 fw-bold">Overs</p>
                        <h6 className="m-0 fw-bolder">
                          {runDetail.overs || 0}
                        </h6>
                      </div>
                    </div>
                  )
              )
            )}
          </div>
          <div className="col-2 p-0 bg-danger d-flex align-items-center justify-content-center rounded-top-3 rounded-start-0">
            <div className="text-white d-flex">
              {match.status === "Live" && <i className="bi bi-record-fill"></i>}
              <span className="ms-1 fw-bolder">{match.status}</span>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-8 p-0 d-flex justify-content-between align-items-center">
            <div className="d-flex ms-3 align-items-center">
              <img
                src={match.team_a?.image}
                className="custom-img-35 rounded-circle"
                alt={match.team_a?.name}
              />
              <div className="fw-bold ps-1">{match.team_a?.code}</div>
            </div>
            <i className="m-0 fw-bold text-secondary px-2">V/S</i>
            <div className="d-flex align-items-center me-1">
              <div className="fw-bold pe-1">{match.team_b?.code}</div>
              <img
                src={match.team_b?.image}
                className="custom-img-35 rounded-circle"
                alt={match.team_b?.name}
              />
            </div>
          </div>
          <div className="col-4 p-0 mt-3 d-flex pe-3 justify-content-end">
            <div>
              <button
                onClick={() => navigate("/join-match", { state: { match } })}
                className={`border rounded-pill ps-2 greenShadowBg text-white fw-bold 
                 
                  ${
                    openForBetByState
                      ? "greenShadowBg"
                      : closeOrOpenBet?.matchId === match?.matchId &&
                        closeOrOpenBet?.status
                      ? "greenShadowBg"
                      : "disabledDiv"
                  }
                `}
                // disabled={
                //   !openForBetByState ||
                //   (closeOrOpenBet?.matchId === match?.matchId &&
                //     !closeOrOpenBet?.status)
                // }
              >
                Bet Now<i className="bi bi-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="w-75 lightYellowBg">
          <i className="bi bi-trophy ps-2 text-danger"></i>
          <span className="px-2 text-danger fw-bold">2000</span>
          <span className="fs-12 text-secondary">Contestants Joined</span>
        </div>
      </div>
    </>
  );
};

export default LiveMatch;
